<script>
  //import { Navigate } from 'svelte-router-spa'
  import Navbar from "./Navbar.svelte";
  import Footer from "./Footer.svelte";
</script>

<svelte:head>
  <title>Mostafa Ballaj - About</title>
  <meta property="Mostafa Ballaj design Portfolio" content="Mostafa Ballaj 3D UX UI portfolio" />
  <link rel="canonical" href="https://www.mostafaballaj.com/about" />

  <meta name="description" content="Mostafa ballaj's UX/UI desinger based in Morocco, branding work using 3d software" />
  <meta name="keywords" content="Mostafa, ballaj, mostafa ballaj, UX, UI, UX/UI, Design, Morocco, branding, Marketing, 3d, 3d software, learn UXUI, wireframe, AI, AI UXUI, 3d UI, AI 3d, artwork, moroccan art, Jewelry, moroccan architecture, moroccan design, architechure marocaine" />
  <meta property="article:author" content="https://www.mostafaballaj.com/about" />

</svelte:head>

<!-- <Navigate.default to="/"> -->
<!-- </Navigate.default> -->

<!-- navbar -->
<Navbar />
<!-- navbar -->

<!-- content -->

<body class=" h-max bg-[#fff6ed] ">

  <div class=" h-10 "></div>

  <div class=" ml-10 relative md:flex sm:flex">
    <img class="md:mr-10 " src="images/thumbnails/Mostafa-ballaj.jpg" height="200px" width="300px" alt="Mostafa ballaj" />
    <br />
    <div class=" self-center">
      <p class=" md:text-6xl font-extrabold text-green-900 text-4xl">Hi, I'm Mostafa Ballaj</p>
      <br />
      <p class=" text-green-900 text-2xl">I am a Designer from Morocco.</p>
      <br />
      <a target="_blank" href="https://linkedin.com/in/mostafaballaj/" class=" text-green-900 text-xl">LinkedIn Profile</a>
    </div>
  </div>
  <br />
  <div>
    <p class=" text-4xl text-green-900 m-10">My Journey in short</p>
  </div>
  <div class=" grid grid-cols-4 gap-1">
    <p class=" m-10 text-justify text-green-900 font-medium">
      ​My name is Mostafa Ballaj, I am a designer and interested in everything related to the user interface and user experience for digital products and real-world products. I was lucky in my childhood, as I was able to work on the computer at a young age. I had a Siemens Pentium 3 computer, which
      was the best for me at the time.  I learned a lot of things, and the most important thing that I enjoyed was the Encarta Software, which is a very large encyclopedia made by Microsoft. I was searching everywhere clicking on all the pictures, discovering all the things i didn’t know,  I was
      always curious about what I can see in this encyclopedia, it was really amazing. I also played a lot of games on the computer, such as King Of Fighters and Metal Slug I enjoyed a lot, this great experience with computer programs and my interest in them made me belong to this beautiful great
      world.
    </p>
    <p class=" m-10 text-justify text-green-900 font-medium">
      I was able to get a new computer with relatively higher specifications, during which I opened up to the world of the Internet, and the first thing I knew at that time was the digital forums.  I participated a lot in Arab forums such as StarTimes, which was the largest digital forum at that
      time. I was an active member. Very much in anime forums, at that time I was creating a group of topics in which I talk about some of the anime series that I watched, during each topic I designed graphic elements using some photo manipulation softwares. I did not have much experience with
      design programs, but because of my passion for these things, I learned quickly and continued learning until the time of 3D came, here I started a new learning journey, I started learning the Autodesk 3DS Max program reading as much content as i can on  the subject. Then I moved directly to the
      Maxon Cinem4d, during my studies at the Enset High School in Mohammedia
    </p>
    <p class=" m-10 text-justify text-green-900 font-medium">
      where i studied plastic materials, it was a very beautiful experience and I benefited from a lot of things, after that when I finished my studies there, I continued my studies at the Academy of Traditional Arts in Casablanca, I was among the jewelry class. During my studies there for three
      years,I gained many experiences in the fields of design and project management, and thanks to my experiences in the field of plastic materials and jewelry, this was the perfect combination for me, my skills in 3D design with the technological progress in the  jewelry field, i can print them
      using a 3D printer that operates with plastic materials, And then converting it into gold and refine it later, I designed many pieces, And I still design some of them until now. After the passage of time I decided to move to a higher level and design products in general, I worked on a lot of
      designs,
    </p>
    <p class=" m-10 text-justify text-green-900 font-medium">
      ​I noticed that one of the most important things that can make a product succeed is the user experience and its external appearance so that there is harmony in what Between the two characteristics, a great appearance with a beautiful experience gives us a successful product, so now I pay a lot
      of attention to designing products that have a wonderful and user-friendly experience.
    </p>

  </div>

  <br />

  <!-- <p>footer</p> -->

  <Footer />

</body>
