<script>
  //import { Navigate } from 'svelte-router-spa'
  import Navbar from "./Navbar.svelte";
  import Footer from "./Footer.svelte";
  import { onMount } from "svelte";

  import axios from "../../axios-global.js";
  let titlecolor = "text-green-900";
  let titleSize = "text-3xl";

  const v_imageStyle = "h-auto max-w-full rounded-md";
  const v_divStyle = "absolute inset-0 z-10 w-full h-full flex opacity-0 bg-white hover:opacity-70 duration-100 ";
  const v_pStyle = `absolute inset-0 z-10 flex justify-center items-center opacity-0 p-10 hover:opacity-100 duration-100 ${titleSize} font-bold ${titlecolor}`;

  let results = [];


  onMount(async () => {
    try {
      const response = await axios.get("api/ProjectsList.json");
      results = response.data;
      //console.log(v_projects);
    } catch (e) {
      // v_loading = false;
      console.error(e);
    }
  });

  $: v_projects = results.filter(({ ux_filter }) => ux_filter) ;

</script>

<svelte:head>
  <title>Mostafa Ballaj - UX/UI Designer</title>
  <meta property="Mostafa Ballaj design Portfolio" content="Mostafa Ballaj 3D UX UI portfolio" />
  <link rel="canonical" href="https://www.mostafaballaj.com/ux" />

  <meta name="description" content="Mostafa ballaj's UX/UI desinger based in Morocco, branding work using 3d software" />
  <meta name="keywords" content="Mostafa, ballaj, mostafa ballaj, UX, UI, UX/UI, Design, Morocco, branding, Marketing, 3d, 3d software, learn UXUI, wireframe, AI, AI UXUI, 3d UI, AI 3d, artwork, moroccan art, Jewelry, moroccan architecture, moroccan design, architechure marocaine" />
  <meta property="article:author" content="https://www.mostafaballaj.com/about" />

</svelte:head>

<!-- <Navigate.default to="/"></Navigate.default> -->

<!-- navbar -->
<Navbar />
<!-- navbar -->

<!-- content -->

<body class=" h-max bg-[#fff6ed] ">

  <div class=" h-10 "></div>

  <div class="grid grid-cols-2 md:grid-cols-4 gap-4 pl-10 pr-10">

    {#each v_projects as project}
      <figure class=" relative">
        <a href={project.link}>
          <img class={v_imageStyle} src={project.image} alt={project.title_alt} />
          <div class={v_divStyle}>
            <p class={v_pStyle}>
              {#if project.locked}
                <i class="fa-solid fa-lock absolute "></i>
                <br />
                <br />
                <br />
              {/if}
              {project.title_alt}
            </p>

          </div>
        </a>
      </figure>
    {/each}
  </div>

  <br />

  <!-- <p>footer</p> -->

  <Footer />

</body>
