<script>
    import Navbar from "../Navbar.svelte";
    import Footer from "../Footer.svelte";
  
  </script>
  
  <svelte:head>
    <title>Mostafa Ballaj - UX/UI Designer</title>
    <meta property="description" content="hello world" />
  </svelte:head>
  
  <!-- navbar -->
  <Navbar />
  <!-- navbar -->
  
  <!-- content -->
  
  <body class=" h-max bg-[#fff6ed] ">
  
    <div class=" h-10 "></div>
  
    <div class=" place-items-center grid grid-cols-1 w-full" >
      <p class=" text-3xl font-medium text-green-900 mb-4" >UI grid system (16:9 ratio)</p>
      <div class=" grid grid-cols-1 gap-4" >
        <img src="images/media/grid-blocks-0.jpg" alt=" " height="1000" width="800" />
      </div>
        <p class="mt-4 " >Download Adobe XD file: </p>
        <div class= "  " >
            <button class="" >
                <a href="" target="_blank">
                    <svg  height="60" width="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g  stroke-width="0"></g>
                        <g id="" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id=""> <path fill-rule="evenodd" class="fill-[#919191]"
                        clip-rule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V12.1893L14.4697 10.4697C14.7626 10.1768 15.2374 10.1768 15.5303 10.4697C15.8232 10.7626 15.8232 11.2374 15.5303 11.5303L12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303L8.46967 11.5303C8.17678 11.2374 8.17678 10.7626 8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697L11.25 12.1893V7C11.25 6.58579 11.5858 6.25 12 6.25ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H8Z" 
                        ></path> </g>
                    </svg>    
                </a>
              </button>
        </div>
  
    </div>
  
    <br />
  
    <!-- <p>footer</p> -->
  
    <Footer />
  
  </body>
  