import Home from './views/public/Home.svelte'
//import LunchMenuView from './views/public/LunchMenuView.svelte'
//import LunchMenuAdmin from './views/admin/LunchMenuAdmin.svelte'
//import AdminLayout from './views/admin/AdminLayout.svelte'
//import LunchMenuAdminDetails from './views/admin/LunchMenuAdminDetails.svelte'
//import Callback from './views/admin/Callback.svelte'

import Design from './views/public/Design.svelte'
import ThreeD from './views/public/3D.svelte'
import UX from './views/public/UX.svelte'
import UI from './views/public/UI.svelte'
import About from './views/public/About.svelte'
import Ideas from './views/public/Ideas.svelte'



import A1 from './views/public/articles/mobile-app-branding.svelte'
import A2 from './views/public/articles//ui-grid-system-by-mostafa-ballaj.svelte'
import A3 from './views/public/articles/ui-grid-blocks.svelte'
import A4 from './views/public/articles/A4.svelte'




const routes = [
  { name: '/', component: Home },

  { name: '/Design', component: Design},
  { name: '/3D', component: ThreeD},
  { name: '/UX', component: UX},
  { name: '/UI', component: UI},
  { name: '/About', component: About},
  { name: '/Ideas', component: Ideas},
  { name: '/mobile-app-branding', component: A1},
  { name: '/ui-grid-system-by-mostafa-ballaj', component: A2},
  { name: '/ui-grid-blocks', component: A3},

  { name: '/a3', component: A3},
  { name: '/a4', component: A4},





  //{
  //   name: '/admin/manage-menus',
  //   component: AdminLayout,
  //   nestedRoutes: [
  //     { name: 'index', component: LunchMenuAdmin },
  //     { name: 'week-details/:lunchWeekId', component: LunchMenuAdminDetails },
  //   ],
  // },
]

export { routes }
