<script>
    import { onMount } from 'svelte';
    import { navigateTo } from "svelte-router-spa";


const OpenDesign = () => {
    const route = "/Design";
    navigateTo(route);
  };

const Open3D = () => {
    const route = "/3D";
    navigateTo(route);
  };

const OpenUX = () => {
    const route = "/UX";
    navigateTo(route);
  };

const OpenUI = () => {
    const route = "/UI";
    navigateTo(route);
  };

const OpenIdeas = () => {
    const route = "/Ideas";
    navigateTo(route);
  };

const OpenAbout = () => {
    const route = "/About";
    navigateTo(route);
  };


</script>

<!-- navbar -->
<div class=" bg-green-900 h-32 grid items-center " role="navigation">
  <div class="grid grid-cols-3 pr-10 pl-10">

    <div class="hidden md:flex gap-8 items-center">
      <button on:click={OpenDesign} class="text-gray-100" >Design</button>
      <button on:click={Open3D} class="text-gray-100" >3D</button>
      <button on:click={OpenUX} class="text-gray-100" >UX</button>
      <button on:click={OpenUI} class="text-gray-100" >UI</button>
      <button on:click={OpenIdeas} class="text-gray-100" >Ideas</button>
      <button on:click={OpenAbout} class="text-gray-100" >About</button>

    </div>

    <div class=" text-gray-300 text-2xl lg:text-3xl font-bold ">
     <a class="flex items-center gap-4 justify-center" href="/">
      <img src="images/logo/Mostafa-ballaj-logo.svg" alt="Mostafa ballaj" height="50" width="50" />
      <p class=" hidden xl:flex" >MOSTAFA BALLAJ</p>
     </a>
    </div>

    <div class="hidden md:flex gap-8 justify-end items-center ">
      <a class="text-gray-100 " href="https://linkedin.com/in/mostafaballaj/" target="_blank">
        <i class="fa-brands fa-linkedin-in fa-lg" style="color: #dedede;"></i>
      </a>
      <a class="text-gray-100 " href="https://instagram.com/mostafaballaj/" target="_blank">
        <i class="fa-brands fa-instagram fa-lg" style="color: #dedede;"></i>
      </a>
      <a class="text-gray-100 " href="https://behance.net/blj/" target="_blank">
        <i class="fa-brands fa-behance fa-lg" style="color: #dedede;"></i>
      </a>
      <a class="text-gray-100 " href="/">
        <i class="fa-regular fa-envelope fa-lg" style="color: #dedede;"></i>
      </a>
    </div>

    <div></div>

    <div class=" md:hidden flex justify-end items-center ">
      <i class="fa-solid fa-bars fa-2xl" style="color: #e3e3e3;"></i>
    </div>

  </div>
</div>
<!-- navbar -->
