<script>
  import Navbar from "../Navbar.svelte";
  import Footer from "../Footer.svelte";

</script>

<svelte:head>
  <title>Mostafa Ballaj - UX/UI Designer</title>
  <meta property="description" content="hello world" />
</svelte:head>

<!-- navbar -->
<Navbar />
<!-- navbar -->

<!-- content -->

<body class=" h-max bg-[#fff6ed] ">

  <div class=" h-10 "></div>

  <div class=" place-items-center grid grid-cols-1 w-full" >
    <p class=" text-3xl font-medium text-green-900 mb-4" >Mobile app branding</p>
    <div>
      <img src="images/thumbnails/Phone_render_branding_3d.jpg" alt=" " height="1000" width="800" />
    </div>

  </div>

  <br />

  <!-- <p>footer</p> -->

  <Footer />

</body>
